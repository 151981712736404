<template>
  <div class="hero">
    <b-container class="p-5">
      <b-row>
        <b-col sm="12" md="7">
          <img :src="logo" alt="logo" width="200px" />
          <h1 class="fs--md--82 fw--700 color--primary">Dakisolatie</h1>
          <h3 class="fs--md--46 subHeading mb-4 color--primary">
            Bespaar <span class="text-white">honderden euro's</span> per jaar
            met een warm huis
          </h3>
          <h5 class="mb-4">
            In een woning gaat <b>30% van de warmte verloren</b> via het dak.
            Logisch dat je dit wil vermijden.
          </h5>
          <h5>
            Vergelijk nu gratis dakisolatie offertes en geniet van de premie!
          </h5>
          <div>
            <img
              :src="red_arrow"
              alt="arrow"
              width="170px"
              class="d-none d-md-block ml-auto"
            />
            <div class="d-block d-md-none">
              <b-row
                class="justify-content-between align-items-end small_screen_images"
              >
                <img
                  :src="small_screen_arrow"
                  alt="arrow"
                  width="25%"
                  height="90px"
                />
                <img :src="hero" alt="image" width="45%" />
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="5" class="d-md-flex align-items-center d-lg-block">
          <div>
            <img
              :src="hero"
              alt="image"
              width="85%"
              class="d-none d-md-block"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
import hero from "@/assets/hero_img.png";
import red_arrow from "@/assets/red_arrow.png";
import small_screen_arrow from "@/assets/small_screen_arrow.png";
export default {
  name: "HeroSection",
  data() {
    return {
      logo,
      hero,
      red_arrow,
      small_screen_arrow,
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background: rgba(232, 251, 251, 1);
  & .subHeading {
    letter-spacing: -2px;
    & span {
      background-color: rgb(255, 102, 0);
    }
  }
  & h5 {
    font-weight: 400;
  }
  & .small_screen_images {
    margin-bottom: -20px;
    z-index: 9;
    position: relative;
  }
}
</style>
