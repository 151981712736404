<template>
  <div class="opgelet py-5">
    <b-container class="p-5">
      <p class="text-white fs--18 mb-4">
        <b>OPGELET: JE DAK ISOLEREN IS WETTELIJK VERPLICHT!</b>
      </p>
      <p class="text-muted pr-0 pr-lg-5">
        Dakisolatie is al enkele jaren verplicht! Vandaag krijg je zelfs
        strafpunten als je woning niet voldoet aan de dakisolatienorm. Dit kan
        leiden tot een <b>ongeschiktverklaring</b>. De minimale R-waarde van
        dakisolatie moet groter of <b>gelijk aan 0,75 m2K/W</b> zijn.
      </p>
      <p class="text-muted mb-5">
        Is jouw dak nog niet geïsoleerd of haalt het de dakisolatienorm niet?
        Laat het dan snel isoleren door een erkende vakman!
      </p>
      <b-button
        href="#form"
        size="lg"
        class="btn-warning px-5"
        variant="warning"
      >
        <span class="text-white fw--700">Vraag gratis advies</span>
      </b-button>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "OpgeletSection",
};
</script>

<style lang="scss">
.opgelet {
  background: rgba(21, 47, 46, 1);
}
</style>
