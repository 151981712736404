<template>
  <div><quiz-temp /></div>
</template>

<script>
import QuizTemp from "@/components/Dakreiniging/QuizTemp.vue";

export default {
  name: "Dakreiniging",
  components: {
    QuizTemp,
  },
};
</script>
<style scoped>
.home {
  width: 100%;
}
</style>
