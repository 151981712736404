<template>
  <div>
    <HeroSection />
    <FormSection />
    <WaaromSection />
    <NogSection />
    <OpgeletSection />
    <HoeSection />
    <VoorwaardenSection />
    <VerhoogdeSection />
    <Footer />
  </div>
</template>

<script>
import HeroSection from "@/components/Dakisolatie/HeroSection.vue";
import FormSection from "@/components/Dakisolatie/FormSection.vue";
import WaaromSection from "@/components/Dakisolatie/WaaromSection.vue";
import NogSection from "@/components/Dakisolatie/NogSection.vue";
import OpgeletSection from "@/components/Dakisolatie/OpgeletSection.vue";
import HoeSection from "@/components/Dakisolatie/HoeSection.vue";
import VoorwaardenSection from "@/components/Dakisolatie/VoorwaardenSection.vue";
import VerhoogdeSection from "@/components/Dakisolatie/VerhoogdeSection.vue";
import Footer from "@/components/Dakisolatie/Footer.vue";

export default {
  name: "Dakisolatie",
  components: {
    HeroSection,
    FormSection,
    WaaromSection,
    NogSection,
    OpgeletSection,
    HoeSection,
    VoorwaardenSection,
    VerhoogdeSection,
    Footer,
  },
  created() {
    document.title = "Mijn-Offertes Dakisolatie";
  },
};
</script>
