import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  dakisolatieLoader: false,
  dakisolatieId: "",
};

const getters = {
  sakisolatieLoader: (state) => state.dakisolatieLoader,
  dakisolatieId: (state) => state.dakisolatieId,
};

const actions = {
  async post_lead_form({ commit }, payload) {
    commit("setDakisolatieLoader", true);
    try {
      const response = await leads.postDakisolatieLead(payload);
      commit("setDakisolatieLoader", false);
      const responseid = response.data.profile.id;
      commit("idDakisolatie", responseid);
      return {
        success: true,
        profileid: responseid,
      };
    } catch (error) {
      commit("setDakisolatieLoader", false);
      alert(error.response.data.error);
      return {
        success: false,
      };
    }
  },
};

const mutations = {
  setDakisolatieLoader(state, payload) {
    state.dakisolatieLoader = payload;
  },
  idDakisolatie(state, payload) {
    state.dakisolatieId = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
