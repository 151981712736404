import DakreinigingRepository from "./DakreinigingRepository";
import SpanplafondRepository from "./SpanplafondRepository";
import DakisolatieRepository from "./DakisolatieRepository";
const LEADS = "leads";
import qs from "qs";

export default {
  postLead(payload) {
    return DakreinigingRepository.post(`${LEADS}`, qs.stringify(payload));
  },
  postSpanplafondLead(payload) {
    return SpanplafondRepository.post(`${LEADS}`, qs.stringify(payload));
  },
  postDakisolatieLead(payload) {
    return DakisolatieRepository.post(`${LEADS}`, qs.stringify(payload));
  },
};
