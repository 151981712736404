<template>
  <div>
    <header class="py-4 px-3"><img :src="logo" class="logo" /></header>
    <b-card>
      <h2 class="font-weight-bold fs--28 heading">Spanplafond Offertes</h2>
      <h3 class="subheading m-0 fs--22">
        Vraag 3 gratis offertes op maat in 2 minuten!
      </h3>
      <img :src="arrow" class="arrow_img" />
      <Question class="question_cardM" />

      <div class="bg_img">
        <b-card class="qCard px-0">
          <h5 class="qCard__heading px-4 fs--18">Bespaar tijd & geld</h5>
          <b-card-text class="px-sm-4 px-0 fs--14">
            Vraag offertes op maat van betrouwbare spanplafond installateurs.
            <br />Uw aanvraag is
            <strong>gratis en volledig vrijblijvend!</strong>
          </b-card-text>
          <Question class="question_card" />
          <div
            class="d-sm-flex d-block justify-content-between align-items-center px-sm-4 px-0 mt-4 mb-1"
          >
            <div class="check_points mt-sm-0 mt-3 ml-2 ml-md-0">
              <img :src="check" width="25" />
              <p class="mb-0 font-weight-bold">Gratis offertes</p>
            </div>
            <div class="check_points mt-sm-0 mt-3 ml-2">
              <img :src="check" width="25" />
              <p class="mb-0 font-weight-bold">Erkende installateurs</p>
            </div>
            <div class="check_points mt-sm-0 mt-3 ml-2">
              <img :src="check" width="25" />
              <p class="mb-0 font-weight-bold">Snelle plaatsing</p>
            </div>
          </div>
          <img
            :src="bg_img"
            class="rounded mt-3 d-sm-none d-block"
            width="100%"
          />
        </b-card>
      </div>
    </b-card>
    <div class="mx-3 my-5 pb-5 footer">
      <small>Copyright 2022 Mijn-Offertes.be</small><br />
      <small
        ><span v-b-modal.privacybeleid class="cursor-pointer"
          >Privacybeleid</span
        >
        |

        <span v-b-modal.Gegevensbescherming class="cursor-pointer"
          >Gegevensbescherming (GDPR)</span
        >
      </small>
    </div>
    <!-- modal -->
    <b-modal size="lg" id="privacybeleid" hide-footer hide-header>
      <iframe
        src="https://www.bobex.be/nl-be/privacybeleid/"
        sandbox="allow-scripts"
        frameborder="0"
        width="100%"
        height="500px"
      ></iframe>
    </b-modal>
    <b-modal size="lg" id="Gegevensbescherming" hide-footer hide-header>
      <iframe
        src="https://www.bobex.be/nl-be/bobex-gegevensbescherming-gdpr/"
        sandbox="allow-scripts"
        frameborder="0"
        width="100%"
        height="500px"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
import logo from "../../assets/imgs/mijn-offerte.png";
import arrow from "../../assets/imgs/arrow-curl-right.png";
import check from "../../assets/imgs/check-mark.png";
import bg_img from "../../assets/imgs/spanplafond-bg.jpg";
import Question from "./Question.vue";

export default {
  components: { Question },
  name: "QuizTemp",
  props: {
    msg: String,
  },
  data() {
    return {
      logo,
      arrow,
      check,
      bg_img,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo {
  width: 20%;
  @media (max-width: 600px) {
    width: 50%;
  }
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.heading {
  font-family: "Montserrat", sans-serif;
  color: rgb(69, 69, 69) !important;
}
.subheading {
  font-family: "Montserrat", sans-serif;
  color: rgb(26, 147, 111);
  font-weight: 600;
}
.arrow_img {
  position: absolute;
  left: 70px;
  z-index: 9999;
  width: 60px;
  margin-top: 15px;
  @media (max-width: 600px) {
    display: none;
  }
}
.bg_img {
  background-image: url("../../assets/imgs/spanplafond-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 70px 102px 70px 102px;
  margin-top: 30px;
  @media (max-width: 600px) {
    background-image: none !important;
    padding: 0 !important;
  }
}
.question_card {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
.question_cardM {
  display: none;
  @media (max-width: 600px) {
    display: block;
    margin: 20px 0;
  }
}
.qCard {
  @media (max-width: 600px) {
    padding: 0;
    border: none !important;
  }
  .card-body {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 600px) {
      padding: 0;
    }
    .qCard__heading {
      color: rgb(242, 100, 59);
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      @media (max-width: 600px) {
        padding: 0 !important;
      }
    }
  }
}
.check_points {
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin-left: 10px;
  }
}
.footer small span {
  color: #007bff !important;
}
</style>
