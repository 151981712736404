<template>
  <div><quiz-temp /></div>
</template>

<script>
import QuizTemp from "@/components/Spanplafond/QuizTemp.vue";

export default {
  name: "Spanplafond",
  components: {
    QuizTemp,
  },
  created() {
    document.title = "Mijn-Offertes Spanplafond";
  },
};
</script>
<style scoped>
.home {
  width: 100%;
}
</style>
