<template>
  <div class="question px-4 py-2">
    <b-progress :max="max" class="mb-3" height="0.5rem">
      <b-progress-bar
        :variant="barColors[this.valueIndex]"
        :value="values[this.valueIndex]"
        :style="`opacity: ${this.opacityValue[this.valueIndex]}`"
      ></b-progress-bar>
    </b-progress>
    <p class="text-right text-white m-0">{{ this.valueIndex }} of 9</p>

    <b-form-group
      v-if="step == 1"
      :label="questions[0].question.title"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        v-model="selected"
        :options="questions[0].question.options"
        class="mb-3 d-flex flex-column"
        :aria-describedby="ariaDescribedby"
        value-field="value"
        text-field="text"
      >
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      v-if="step == 2"
      :label="questions[1].question.title"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        v-model="selected"
        :options="questions[1].question.options"
        class="mb-3 d-flex flex-column"
        :aria-describedby="ariaDescribedby"
        value-field="value"
        text-field="text"
      >
      </b-form-radio-group>
    </b-form-group>
    <!-- Firstname -->
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        v-if="step == 3"
        id="Voornaam "
        label="Voornaam *"
        label-for="Voornaam "
      >
        <b-form-input
          :state="fNameValidation"
          id="Voornaam "
          v-model="form.firstname"
          placeholder="Voornaam"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <!-- Lastname -->
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        v-if="step == 4"
        id="Achternaam "
        label="Achternaam *"
        label-for="Achternaam "
      >
        <b-form-input
          id="Achternaam "
          :state="lNameValidation"
          v-model="form.lastname"
          placeholder="Achternaam"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <!-- address -->
    <b-form @submit.prevent="onSubmit" v-if="step == 5">
      <div class="row">
        <div class="col-7">
          <b-form-group
            id="Address  "
            label="Straatnaam  *"
            label-for="Address  "
          >
            <b-form-input
              id="Address  "
              :state="addressValidation"
              v-model="form.street"
              placeholder="Straatnaam"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-5">
          <b-form-group
            id="huisnr  "
            label="Huisnummer  *"
            label-for="huisnr  "
          >
            <b-form-input
              id="huisnr  "
              :state="houseValidation"
              v-model="form.house_number"
              placeholder="Huisnummer "
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-form>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        v-if="step == 6"
        id="Postcode"
        label="Postcode *"
        label-for="Postcode"
      >
        <b-form-input
          id="Postcode"
          :state="zipValidation"
          v-model="form.zip"
          maxLength="6"
          placeholder="Postcode"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <!-- email -->
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        v-if="step == 7"
        id="E-mail"
        label="E-mail *"
        label-for="E-mail"
      >
        <b-form-input
          id="E-mail"
          :state="emailValidation"
          type="email"
          v-model="form.email"
          placeholder="E-mail"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <!-- phone -->
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        v-if="step == 8"
        id="Telefoonnummer"
        label="Telefoonnummer *"
        label-for="Telefoonnummer"
      >
        <b-form-input
          id="Telefoonnummer"
          :state="phoneValidation"
          type="tel"
          maxLength="10"
          v-model="form.phone_number"
          placeholder="Telefoonnummer"
          required
        ></b-form-input>
      </b-form-group>
      <!-- checkbox -->
      <div v-if="step == 9">
        <label for="checkbox">Bevestiging *</label>
        <b-form-checkbox-group v-model="form.answers">
          <b-form-checkbox id="checkbox" value="3379"
            >Ja, ik wens gratis dakreiniging offertes te
            vergelijken.</b-form-checkbox
          >
        </b-form-checkbox-group>
      </div>

      <!-- buttons -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="danger"
          v-if="step >= 2"
          @click="preStep"
          class="mr-3"
          >Vorige</b-button
        >
        <b-button
          v-if="step <= 8"
          variant="success"
          type="submit"
          @click.prevent="onSubmit"
          >Volgende</b-button
        >
        <b-button v-else variant="warning" @click.prevent="submitForm"
          ><b-spinner v-if="getLoader" /><span v-else>Verzenden</span></b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Question",
  data() {
    return {
      selected: "",
      step: "1",
      temp_answers: [],
      validationCheck: true,
      emailErr: true,
      house_number: "",
      form: {
        language: "nl_BE",
        firstname: "",
        lastname: "",
        street: "",
        house_number: "",
        email: "",
        phone_number: "",
        zip: "",
        answers: [],
        site_custom_url: "https://mijnoffertes.verbeterthuis.be/",
        site_custom_name: "Mijn-Offertes Dakreiniging",
        site_subid: "",
        publisher_id: "",
      },

      questions: [
        {
          question: {
            title: "Welk type dak heeft u?",
            options: [
              { text: "Dakpannen", value: "3349" },
              { text: "Leien", value: "3352" },
              { text: "Roofing of EPDM (plat dak)", value: "3355" },
              { text: "Ander", value: "3358" },
            ],
          },
        },
        {
          question: {
            title: "Hoe groot is uw dak?",
            options: [
              { text: "Minder dan 30 m²", value: "3364" },
              { text: "Tussen 30 en 100 m²", value: "3367" },
              { text: "Tussen 100 en 500 m²", value: "3370" },
              { text: "Meer dan 500 m²", value: "3373" },
            ],
          },
        },
      ],
      opacityValue: [
        "1",
        "0.75",
        "0.5",
        "0.25",
        "0.5",
        "0.75",
        "0.5",
        "0.75",
        "0.8",
        "1",
      ],
      valueIndex: "1",
      barColors: [
        "warning",
        "warning",
        "warning",
        "warning",
        "info",
        "info",
        "success",
        "success",
        "success",
        "success",
        "success",
      ],
      values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      max: 9,
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    onSubmit() {
      if (this.step == 3) {
        if (this.form.firstname == "") {
          this.validationCheck = false;
        }
      }
      if (this.step == 4) {
        if (this.form.lastname == "") {
          this.validationCheck = false;
        }
      }
      if (this.step == 5) {
        if (this.form.street == "" || this.form.house_number == "") {
          this.validationCheck = false;
        }
      }
      if (this.step == 6) {
        if (this.form.zip == "") {
          this.validationCheck = false;
        }
      }
      if (this.step == 7) {
        if (!this.emailErr) {
          this.confirmEmail();
        } else if (this.form.email == "") {
          this.validationCheck = false;
        }
      }
      if (this.step == 8) {
        if (this.form.phone_number == "") {
          this.validationCheck = false;
        }
      }

      if (this.validationCheck && this.emailErr) {
        this.step++;
        this.valueIndex++;

        if (this.step == 2) {
          if (this.selected) {
            this.temp_answers.push(this.selected);
            this.selected = "";
          }
        }
        if (this.step == 3) {
          if (this.selected) {
            this.temp_answers.push(this.selected);
            this.selected = "";
          }
        }
      }
    },
    preStep() {
      this.step--;
      this.valueIndex--;
      this.validationCheck = true;

      if (this.step == 1) {
        this.temp_answers.splice(-1);
      }
      if (this.step == 2) {
        this.temp_answers.splice(-1);
      }
    },
    confirmEmail() {
      this.$bvModal
        .msgBoxOk(
          "Je e-mail adres lijkt een spelfout te bevatten. Gelieve deze te controleren voordat u verder gaat.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Oké",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          this.emailErr = value;
        });
    },
    async submitForm() {
      let check1 = this.temp_answers.includes("3358");
      let check2 = this.temp_answers.includes("3349");
      let check3 = this.temp_answers.includes("3352");
      let check4 = this.temp_answers.includes("3355");
      let firstStep = !check1 && !check2 && !check3 && !check4;
      if (firstStep) {
        this.temp_answers.push("3361");
      }
      let check11 = this.temp_answers.includes("3364");
      let check12 = this.temp_answers.includes("3367");
      let check13 = this.temp_answers.includes("3370");
      let check14 = this.temp_answers.includes("3373");
      let secondStep = !check11 && !check12 && !check13 && !check14;
      if (secondStep) {
        this.temp_answers.push("3376");
      }
      let check21 = this.form.answers.includes("3379");
      if (!check21) {
        this.temp_answers.push("3382");
      } else {
        this.temp_answers.push("3379");
      }

      let resp = await this.postLead({
        ...this.form,
        answers: [...this.temp_answers, "3346"],
        site_subid: this.$route.query.oa_id,
        publisher_id: this.$route.query.site,
      });
      if (resp.success == true) {
        this.$router.push("Bedankt");
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader"]),
    fNameValidation() {
      if (this.form.firstname == "") {
        return null;
      }
      let name = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(name)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "") {
        return null;
      }
      let name = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(name)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    addressValidation() {
      if (this.form.street == "") {
        return null;
      }
      if (this.form.street != null) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      if (this.form.house_number == "") {
        this.validationCheck = false;
        return null;
      }

      let houseRegex = /(\w?[0-9]+[a-zA-Z0-9\- ]*)/;
      if (this.form.house_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.house_number.match(houseRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zipValidation() {
      if (this.form.zip == "") {
        return null;
      }
      let zipCheck = /^([0-9]){4}$/;
      if (this.form.zip.match(zipCheck)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      const email = this.form.email.split("@");
      let check = email[1];
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        if (
          check == `gmail.com` ||
          check == `outlook.com` ||
          check == `yahoo.com` ||
          check == `icloud.com`
        ) {
          this.validationCheck = true;
          this.emailErr = true;
        } else {
          this.emailErr = false;
          this.validationCheck = true;
        }
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        return null;
      }
      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style>
.question {
  background-color: rgba(238, 238, 238, 1) !important;
}
.bg-info {
  background-color: rgb(239, 231, 127) !important;
}
</style>
