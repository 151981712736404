<template>
  <div class="bg-white nog">
    <b-container class="p-5">
      <div class="text-center">
        <img :src="image" width="85%" class="topImg" />
      </div>
      <b-row>
        <b-col sm="12" md="8" class="py-5">
          <p class="fs--18 color--primary">
            <b>NOG MEER VOORDELEN VAN DAKISOLATIE</b>
          </p>
          <p class="fw--16 mb-4">
            Alle energiespecialisten zijn het erover eens: dakisolatie is de
            meest effectieve ingreep om uw woning energiezuiniger te maken. Je
            <b>bespaart 25% tot zelfs 30% energie</b>. Maar er zijn nog
            verschillende redenen om je dak te isoleren:
          </p>
          <b-row class="mb-2" v-for="(data, index) in points" :key="index">
            <b-col cols="2" md="2" lg="1">
              <img :src="mark" width="30px" />
            </b-col>
            <b-col cols="10">
              <p class="fs--18 mb-0 color--primary">
                <b>{{ data.title }}</b>
              </p>
              <p>
                {{ data.subTitle }}
              </p>
            </b-col>
          </b-row>
          <b-button
            href="#form"
            size="lg"
            class="btn-warning px-5"
            variant="warning"
          >
            <span class="text-white fw--700">Vraag gratis advies</span>
          </b-button>
        </b-col>
        <b-col sm="12" md="4" class="d-flex align-items-center">
          <div>
            <img :src="nog" width="100%" />
            <p class="fw--300"><i>Voorbeeld van isolatieplaat</i></p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import image from "@/assets/dakisolatie.jpg";
import nog from "@/assets/nog.jpg";
import mark from "@/assets/imgs/check-mark.png";

export default {
  name: "NogSection",
  data() {
    return {
      image,
      nog,
      mark,
      points: [
        {
          title: "HOGER WOONCOMFORT",
          subTitle:
            "Dakisolatie verhoogt uw warmtecomfort. Want een geïsoleerd dak houdt de warmte binnen in de winter. Zo maakt u van uw woning een gezelligere plek om in te wonen",
        },
        {
          title: "AKOESTISCHE ISOLATIE",
          subTitle:
            "De isolatie in uw dak houdt immers niet alleen de warmte binnen, maar dempt ook vervelende buitengeluiden zoals langsrijdende treinen, druk verkeer, een snelweg in de buurt, burenlawaai, enzovoort.",
        },
        {
          title: "HOGERE WONINGWAARDE",
          subTitle:
            "Dakisolatie verlaagt het E-peil van uw woning. Daardoor krijgt u een positiever EPC-attest en verhoogt u de waarde van uw gebouw bij een eventuele verkoop.",
        },
        {
          title: "MILIEUVRIENDELIJK",
          subTitle:
            "Aangezien een woning met een geïsoleerd dak minder energie verbruikt, stoot u dus minder CO2 uit. Dakisolatie is dus ook goed voor het milieu.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.nog {
  & .topImg {
    margin-top: -180px;
    @media only screen and (max-width: 767.98px) {
      margin-top: -100px;
    }
  }
}
</style>
