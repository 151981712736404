<template>
  <div class="opgelet py-4">
    <b-container class="p-5">
      <b-row>
        <b-col sm="12" md="8">
          <p class="text-white fs--18 mb-4">
            <b>VERHOOGDE PREMIE: WANNEER EN VOOR WIE?</b>
          </p>
          <p class="text-muted pr-0 pr-lg-5">
            In bepaalde gevallen keert Fluvius zelfs een hoger premiebedrag uit.
            Wie dakisolatie met het
            <b>verwijderen van een asbestdak</b> combineert, heeft recht op een
            premie van 12 euro per m².
          </p>
          <p class="text-muted mb-5">
            Voor <b>beschermde afnemers</b> (personen die recht hebben op de
            sociale maximumprijzen voor elektriciteit en aardgas) loopt de
            premie voor dakisolatie op tot respectievelijk
            <b>10,5 euro per m²</b> (dakisolatie) en
            <b>18,5 euro per m²</b> (combinatie met asbestverwijdering).
          </p>
          <b-button
            href="#form"
            size="lg"
            class="btn-warning px-5 pb-3 pb-md-2"
            variant="warning"
          >
            <span class="text-white fs--19 fw--700"
              >Ontvang gratis offertes</span
            >
          </b-button>
        </b-col>
        <b-col sm="12" md="4" class="rightContent">
          <img :src="image" width="100%" class="d-none d-md-block" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import image from "@/assets/verhoogde.png";

export default {
  name: "VerhoogdeSection",
  data() {
    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
.opgelet {
  background: rgba(21, 47, 46, 1);
}
.rightContent {
  display: flex;
  align-items: end;
  margin-bottom: -120px;
  margin-left: -75px;
}
</style>
