<template>
  <div class="bg-white nog">
    <b-container class="p-5">
      <div class="text-center">
        <img :src="image" width="85%" class="topImg" />
      </div>
      <b-row>
        <b-col sm="12" md="9" class="py-5">
          <p class="fs--18 color--primary">
            <b>VOORWAARDEN</b>
          </p>
          <p class="fw--16 mb-4">
            Om in aanmerking te komen voor de dakisolatiepremie moet je aan
            enkele voorwaarden voldoen:
          </p>
          <b-row class="mb-4" v-for="(data, index) in points" :key="index">
            <b-col cols="2" md="2" lg="1">
              <div class="tag text-white fs--20 fw--600">
                {{ data.number }}
              </div>
            </b-col>
            <b-col cols="10">
              <p class="fs--18 mb-0 color--primary mb-2">
                <b>{{ data.title }}</b>
              </p>
              <p>
                {{ data.subTitle }}
              </p>
            </b-col>
          </b-row>
          <p class="fs--18 color--primary">
            <b>BEDRAG</b>
          </p>
          <p class="fw--16 mb-4">
            Als je een <b>dak of zoldervloer isoleert</b> in een bestaande
            woning of appartement, je de aanvraag hebt ingediend en je aan alle
            voorwaarden voldoet, dan zal netbeheerder Fluvius je een premie
            uitkeren. De premie bedraagt <b>4 euro per m²</b>.
          </p>
        </b-col>
        <b-col sm="12" md="3"> </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import image from "@/assets/voorwaarden.jpg";
import nog from "@/assets/nog.jpg";
import mark from "@/assets/imgs/check-mark.png";

export default {
  name: "VoorwaardenSection",
  data() {
    return {
      image,
      nog,
      mark,
      points: [
        {
          number: "1",
          title: "Bouwjaar vóór 2006",
          subTitle:
            "Deze premie is er alleen voor woningen, appartementen of appartementsgebouwen in het Vlaamse gewest die vóór 1-1-2006 werden aangesloten op het elektriciteitsnet",
        },
        {
          number: "2",
          title: "Deadline: 24 maanden na de eindfactuurdatum ",
          subTitle:
            "Het aanvraagformulier moet bij Fluvius (de elektriciteitsnetbeheerder) aankomen uiterlijk 24 maanden na de eindfactuurdatum.",
        },
        {
          number: "3",
          title: "Erkende aannemer",
          subTitle:
            "Een aannemer moet de werken uitvoeren om in aanmerking te komen voor de premie. ",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.nog {
  & .topImg {
    margin-top: -180px;
    @media only screen and (max-width: 767.98px) {
      margin-top: -100px;
    }
  }
}
.tag {
  background: rgba(21, 47, 46, 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 767.98px) {
    width: 30px;
    height: 30px;
  }
}
</style>
