<template>
  <div>
    <header class="py-4 px-3"><img :src="logo" class="logo" /></header>
    <b-card>
      <h2 class="font-weight-bold fs--28">Dakreiniging Offertes</h2>
      <h3 class="subheading m-0 fs--22">
        Vraag 3 gratis offertes op maat in 2 minuten!
      </h3>
      <img :src="arrow" class="arrow_img" />

      <div class="bg_img">
        <b-card class="qCard px-0">
          <h5 class="qCard__heading px-4 fs--18">Bespaar tijd & geld</h5>
          <b-card-text class="px-sm-4 px-0 fs--14">
            Vraag offertes op maat van betrouwbare dakreiniging specialisten.
            <br />Uw aanvraag is
            <strong>gratis en volledig vrijblijvend!</strong>
          </b-card-text>

          <div class="question px-4 py-2 text-center">
            <h2 class="bedankt">Bedankt voor uw aanvraag!</h2>
            <p>
              Wij brengen u zo snel mogelijk in contact met dakreiniging
              specialisten uit uw buurt.
            </p>
            <p>
              Nog aannemers nodig? Vind aannemers voor elk project in uw
              gemeente op
              <a
                class="bedanktLink"
                href="https://www.bobex.be/nl-be/?aff=bobex"
                target="_blank"
                >Bobex.be</a
              >.
            </p>
            <img
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://republish.verbeterthuis.be/m/4003/ec5cbc7aa21e/?event=4864&unique_conversion_id=${profId}`"
              style="width: 1px; height: 1px; border: 0px"
            />
          </div>
          <div
            class="d-sm-flex d-block justify-content-between align-items-center px-sm-4 px-0 mt-4 mb-1"
          >
            <div class="check_points mt-sm-0 mt-3">
              <img :src="check" width="30" />
              <p class="mb-0 font-weight-bold">Gratis offertes</p>
            </div>
            <div class="check_points mt-sm-0 mt-3">
              <img :src="check" width="30" />
              <p class="mb-0 font-weight-bold">Erkende dakwerkers</p>
            </div>
            <div class="check_points mt-sm-0 mt-3">
              <img :src="check" width="30" />
              <p class="mb-0 font-weight-bold">Slechts 6% btw</p>
            </div>
          </div>
          <img
            :src="bg_img"
            class="rounded mt-3 d-sm-none d-block"
            width="100%"
          />
        </b-card>
      </div>
    </b-card>
    <div class="mx-3 my-5 pb-5">
      <small>Copyright 2022 Mijn-Offertes.be</small><br />
      <small
        ><a href="https://www.bobex.be/nl-be/privacybeleid/">Privacybeleid </a>
        |
        <a href="https://www.bobex.be/nl-be/bobex-gegevensbescherming-gdpr/"
          >Gegevensbescherming (GDPR)</a
        ></small
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import logo from "../assets/imgs/mijn-offerte.png";
import arrow from "../assets/imgs/curve-arrow-point-to-bottom.png";
import check from "../assets/imgs/check-mark.png";
import bg_img from "../assets/imgs/bgimg.jpg";

export default {
  name: "AfterSubmit",
  props: {
    msg: String,
  },
  data() {
    return {
      logo,
      arrow,
      check,
      bg_img,
    };
  },
  computed: mapGetters(["profId"]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bedankt {
  color: #f2643b !important;
}
.bedanktLink {
  color: rgb(51, 51, 51) !important;
  font-weight: 700;
  text-decoration: underline;
}
.logo {
  width: 20%;
  @media (max-width: 600px) {
    width: 50%;
  }
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.subheading {
  color: rgb(26, 147, 111);
  font-weight: 600;
}
.arrow_img {
  position: absolute;
  left: 130px;
  z-index: 9999;
  width: 100px;
  @media (max-width: 600px) {
    display: none;
  }
}
.bg_img {
  background-image: url("../assets/imgs/bgimg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 70px 20px 70px 170px;
  margin-top: 30px;
  @media (max-width: 600px) {
    background-image: none !important;
    padding: 0 !important;
  }
}
.question_card {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
.question_cardM {
  display: none;
  @media (max-width: 600px) {
    display: block;
    margin: 20px 0;
  }
}
.qCard {
  @media (max-width: 600px) {
    padding: 0;
    border: none !important;
  }
  .card-body {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 600px) {
      padding: 0;
    }
    .qCard__heading {
      color: rgb(242, 100, 59);
      font-weight: 500;
      @media (max-width: 600px) {
        padding: 0 !important;
      }
    }
  }
}
.check_points {
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin-left: 10px;
  }
}
.question {
  background-color: rgba(238, 238, 238, 1) !important;
}
.bg-info {
  background-color: rgb(239, 231, 127) !important;
}
</style>
