<template>
  <div class="app"><DakreinigingAfterSubmit /></div>
</template>

<script>
// @ is an alias to /src
import DakreinigingAfterSubmit from "@/layouts/DakreinigingAfterSubmit.vue";

export default {
  name: "Bedankt",
  components: {
    DakreinigingAfterSubmit,
  },
};
</script>
