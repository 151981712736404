<template>
  <div class="app">
    <Spanplafond />
  </div>
</template>

<script>
import Spanplafond from "../layouts/Spanplafond.vue";

export default {
  name: "Home",
  components: {
    Spanplafond,
  },
};
</script>
