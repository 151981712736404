import Vue from "vue";
import VueRouter from "vue-router";
import DakreinigingHome from "../views/DakreinigingHome.vue";
import DakreinigingBedankt from "../views/DakreinigingBedankt.vue";
import SpanplafondHome from "../views/SpanplafondHome.vue";
import SpanplafondBedankt from "../views/SpanplafondBedankt.vue";
import Dakisolatie from "../views/DakisolatieHome.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "DakreinigingHome",
  //   component: DakreinigingHome,
  //   redirect: "/dakreiniging",
  // },
  // {
  //   path: "/dakreiniging",
  //   name: "DakreinigingHome",
  //   component: DakreinigingHome,
  // },
  // {
  //   path: "/bedankt",
  //   name: "DakreinigingBedankt",
  //   component: DakreinigingBedankt,
  //   redirect: "/dakreiniging/bedankt",
  // },
  // {
  //   path: "/dakreiniging/bedankt",
  //   name: "DakreinigingBedankt",
  //   component: DakreinigingBedankt,
  // },

  // {
  //   path: "/Spanplafond",
  //   name: "SpanplafondHome",
  //   component: SpanplafondHome,
  // },
  // {
  //   path: "/Spanplafond/bedankt",
  //   name: "SpanplafondBedankt",
  //   component: SpanplafondBedankt,
  // },
  // {
  //   path: "/Dakisolatie",
  //   name: "Dakisolatie",
  //   component: Dakisolatie,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
