<template>
  <div class="app">
    <dakreiniging />
  </div>
</template>

<script>
import Dakreiniging from "@/layouts/Dakreiniging.vue";

export default {
  name: "Home",
  components: {
    Dakreiniging,
  },
};
</script>
<style lang="scss">
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  width: 768px;
  justify-content: center;
  margin: auto;
  @media (max-width: 600px) {
    width: 320px;
  }
}
</style>
