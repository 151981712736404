<template>
  <div class="waarom">
    <b-container class="p-5 d-flex justify-content-center">
      <b-col sm="12" md="6" class="text-center">
        <h1 class="fs--md--48 fw--700 mb-4">
          Waarom je dak <br />
          isoleren?
        </h1>
        <p class="fw--18 pb-2 pb-md-5 mb-2 mb-md-5">
          Een geïsoleerd dak bezorgt je niet alleen een warme winter en een
          frisse zomer, maar zorgt ook voor akoestische isolatie en zal dus
          geluid absorberen. Gebruik je de ruimte onder het dak als leefruimte?
          Dan is dakisolatie absoluut nodig.
        </p>
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WaaromSection",
};
</script>

<style lang="scss">
.waarom {
  background: rgba(232, 251, 251, 1);
}
</style>
