import Vue from "vue";
import Vuex from "vuex";
import Dakreiniging from "./modules/Dakreiniging";
import Spanplafond from "./modules/Spanplafond";
import Dakisolatie from "./modules/Dakisolatie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Dakreiniging,
    Spanplafond,
    Dakisolatie,
  },
});
