<template>
  <div class="footer py-5">
    <b-container class="p-5">
      <b-row>
        <b-col sm="6" md="6" class="text-left">
          <img :src="logo" alt="logo" width="150px" /> <br />
          <small class="text-muted">© 2022 Mijn-Offertes.be</small>
        </b-col>
        <b-col sm="6" md="6" class="d-flex">
          <span class="ml-0 ml-md-auto">
            <small class="text-muted">Powered by Bobex.be</small> <br />
            <small>
              <span v-b-modal.privacybeleid>Privacybeleid</span>

              |
              <span v-b-modal.Gegevensbescherming
                >Gegevensbescherming (GDPR)</span
              >
            </small>
          </span>
        </b-col>
      </b-row>
    </b-container>
    <!-- modal -->
    <b-modal size="lg" id="privacybeleid" hide-footer hide-header>
      <h2>Privacybeleid</h2>
      <h3>Privacybeleid & bescherming van de persoonlijke levenssfeer</h3>
      <p>
        Bobex verwerkt uw persoonsgegevens, in elektronische of andere vorm,
        voor legitieme doeleinden in het kader van de contractuele relaties,
        bedrijfsactiviteiten en veiligheid/beveiliging.<br />
        Deze doeleinden omvatten, maar zijn niet beperkt tot het volgende:<br /><br />

        -Informatieverzoeken, aanbiedingen en prospects<br />
        -Communicatie aan klanten in het kader van de uitvoering van contracten
        en aan individuen in het kader van offertes.<br /><br />

        In overeenstemming met de wet van 30 juli 2018 betreffende de
        bescherming van natuurlijke personen met betrekking tot
        gegevensverwerking en de algemene verordening Gegevensbescherming van 25
        mei 2018, worden de gegevens u medegedeeld op uw verzoek, en worden ze,
        desgevallend, gecorrigeerd of volledig verwijderd uit onze databanken.
        Alle bijkomende informatie kan bekomen worden bij de
        gegevensbeschermingsautoriteit. Al uw vragen met betrekking tot de
        registratie, wijziging of verwijdering van uw gegevens kunnen gericht
        worden aan Bobex.com NV, Koolmijnenkaai, 62 te B-1080 Brussel, per
        telefoon op het nummer 02/504.00.80 of via mail: privacy@bobex.be, al
        deze aanvragen zullen behandeld worden door de functionaris voor
        gegevensbescherming.<br /><br />

        Bobex wil luisteren naar de klant en de consument en zijn recht om zich
        te allen tijde te verzetten, om redenen die verband houden met zijn
        specifieke situatie, tegen de verwerking van zijn persoonsgegevens.<br />
        In dat geval zal Bobex analyseren of het gepast is om de verwerking van
        persoonsgegevens onmiddellijk stop te zetten, tenzij er dwingende
        legitieme redenen zijn voor de verwerking die zwaarder wegen dan de
        belangen en rechten en vrijheden van de betrokkene, of om wettelijke
        rechten vast te stellen, uit te oefenen of te verdedigen.<br />
        U beschikt over een absoluut recht om u te verzetten tegen de verwerking
        van uw Gegevens, met inbegrip van profilering, ten behoeve van direct
        marketing.<br />
        Bobex zet de nodige middelen in om ervoor te zorgen dat het bewaren van
        persoonsgegevens voor de hierboven beschreven doeleinden en dat de
        wettelijke termijnen niet worden overschreden.<br />
        De gegevens die bestemd zijn voor een dienst van het instellen in
        verband met de instelling worden voor een periode van 3 maanden bewaard.
      </p>
      <br />

      <h3>Bescherming van de persoonlijke levenssfeer</h3>
      <p>
        Onze doelstelling is uw informatie te beschermen, zowel in het kader van
        Internet als in al onze andere types relaties. Het staat u vrij om deze
        site te bezoeken, informatie in te winnen over onze diensten, kennis te
        nemen van het laatste nieuws of van onze nieuwe diensten, zonder ons
        persoonlijke informatie te moeten verstrekken. Indien u echter
        transacties wenst uit te voeren op de site, zult u zich moeten
        inschrijven als lid van Bobex. Zonder uw toestemming wordt geen enkele
        persoonlijke informatie die u aanbelangt doorgegeven aan derden (buiten
        Bobex, onze affiliate sites en onze marketing en/of database partners).
        Wij houden alle gegevens betreffende uw transacties op de Bobex site
        up-to-date, volgens onze gewoonlijke standaards van strikte
        vertrouwelijkheid en veiligheid.
      </p>
      <br />

      <h3>Gebruik van “cookies”</h3>
      <p>
        Wij zullen af en toe cookies gebruiken om u een betere service te
        bieden. Een “cookie” is een klein stuk informatie dat door een website
        opgeslagen wordt in de browser van uw PC (in het algemeen in de map
        C:\WINDOWS\Cookies) : het cookie kan opgehaald worden tijdens een later
        bezoek aan dezelfde site. Het cookie kan niet gelezen worden door een
        andere website dan diegene die het heeft aangemaakt. Wij gebruiken
        cookies voor administratieve doeleinden om, bijvoorbeeld, uw voorkeur te
        registreren voor bepaalde types informatie of uw toegangscode te
        registreren, wat voorkomt dat u bij ieder bezoek aan onze site telkens
        opnieuw dezelfde gegevens moet invoeren via het toetsenbord. De meeste
        cookies werken alleen voor de duur van een enkele sessie, of bezoek.
        Geen enkel ervan bevat informatie waardoor met u contact kan worden
        opgenomen via telefoon, e-mail of “snail mail”. U heeft ook de
        mogelijkheid uw browser zodanig te configureren dat hij u verwittigt
        telkens cookies worden aangemaakt of dat hij de aanmaak ervan
        verhindert.
      </p>
      <br />

      <h3>Beleid inzake persoonlijke levenssfeer en vertrouwelijkheid</h3>
      <p>
        Bobex behoudt zich het recht voor de informatie met betrekking tot de
        berichten, zoals de inhoud, de afkomst of de bestemming ervan, te
        controleren en te onderzoeken, indien het een billijk vermoeden heeft
        dat het geheel of een deel van deze communicatie betrekking heeft op
        illegale of ongeoorloofde activiteiten, of indien het daartoe bevel
        gekregen heeft van de bevoegde autoriteiten. Behalve wanneer de controle
        gebeurt op verzoek van de bevoegde autoriteiten, mag de controle van
        Bobex.com alleen betrekking hebben op de berichten met een openbaar of
        niet-vertrouwelijk karakter, dat wil zeggen de informatie die zich op
        een webpagina bevindt, de berichten die te vinden zijn in de
        discussiegroepen of de informatiemailings, zonder dat deze lijst
        beperkend is. Bobex.com mag de maatregelen treffen die noodzakelijk zijn
        om een goed beheer van het computersysteem te verzekeren. In dit opzicht
        kan het toegang verwerven tot iedere communicatie die is opgeslagen op
        zijn computersysteem. De gebruiker verbindt zich ertoe het geheime en
        vertrouwelijke karakter van zijn wachtwoord en zijn toegangscode te
        bewaren. Ieder gebruik van deze identificatie-elementen gebeurt op
        volledige verantwoordelijkheid van de gebruiker. In geval van verlies of
        diefstal of frauduleus gebruik van een van deze elementen, is de
        gebruiker ertoe gehouden Bobex daarvan binnen de kortst mogelijke
        termijn op de hoogte te stellen, waarbij deze kennisgeving bevestigd
        moet worden per aangetekende brief. De gebruiker wordt pas ontslaan van
        zijn verantwoordelijkheid op de werkdag die volgt op de dag waarop de
        aangetekende brief ontvangen werd. De gegevens met persoonlijk karakter
        betreffende de gebruiker worden opgenomen in de bestanden van Bobex en
        worden gebruikt: met het oog op het beheer van het cliëntenbestand en
        het uitvoeren van marktstudies; met het oog op de uitgifte, de inning en
        de controle van de facturen; in het kader van de contractuele relatie
        met de gebruiker (bijvoorbeeld, bij briefwisseling of in het geval van
        geschil); met het oog op de verwezenlijking van informatie- of
        promotiecampagnes over de producten en diensten van Bobex. De gegevens
        met persoonlijk karakter die de gebruiker verstuurt, worden
        geregistreerd in de databanken van Bobex. De gebruiker beschikt over een
        recht van inzage,van correctie en volledige verwijdering van deze
        gegevens. Elke gebruiker kan dit recht uitoefenen via zijn of haar
        persoonlijke login op bobex.be. Al uw vragen met betrekking tot de
        registratie, wijziging of verwijdering van uw gegevens kunnen gericht
        worden aan Bobex.com NV, Koolmijnenkaai, 62 te B-1080 Brussel, per
        telefoon op het nummer 02/504.00.80 of via mail: privacy@bobex.be.. Voor
        meer informatie kan de gebruiker zich richten tot het openbare register
        bij de gegevensbeschermingsautoriteit. Op voorwaarde van een
        gedagtekende en ondertekende schriftelijke aanvraag, gericht aan Bobex,
        kan de gebruiker die het bewijs levert van zijn identiteit, van Bobex
        gratis de schriftelijke mededeling bekomen van de gegevens met
        persoonlijk karakter die op hem betrekking hebben, evenals,
        desgevallend, de correctie van de gegevens die onjuist, onvolledig of
        niet relevant zijn. In geval van verandering van de administratieve
        gegevens van de gebruiker, is deze ertoe gehouden Bobex daarvan op de
        hoogte te brengen binnen de maand na de wijziging ervan. Bijkomende
        informatie omtrent de diensten en voorwaarden van Bobex, gelieve de FAQ
        (veel gestelde vragen) en Algemene Voorwaarden te lezen.
      </p>
    </b-modal>
    <b-modal size="lg" id="Gegevensbescherming" hide-footer hide-header>
      <h2>Bobex gegevensbescherming (GDPR)</h2>
      <h4>Altijd en overal toegang tot jouw gegevens</h4>
      <p>
        Als Bobex-gebruiker heb je een persoonlijke login. Daarmee kan je jouw
        gegevens bekijken, aanpassen en beheren. Heb je je Bobex-account niet
        meer nodig? Dan kan je je account ook volledig verwijderen.
      </p>
      <br />
      <h4>Volledig zelf onder controle</h4>
      <p>
        In elke stap van onze dienstverlening heb je alles volledig zelf onder
        controle. Je doet zelf een aanvraag. Wij bellen je vervolgens even op en
        overlopen met jou de aanvraag. Je beslist op dat moment zelf of we jouw
        gegevens doorsturen naar onze erkende partners. Daarna kan je onze
        leveranciers evalueren via jouw Bobex-login.
      </p>
      <br />
      <h4>Data privacy voor leveranciers</h4>
      <p>
        Ben je een Bobex-leverancier? Dan heb je toegang tot een beveiligde zone
        op onze website. In dit extranet kan je jouw profiel bekijken en alle
        persoonlijke gegevens aanpassen. Je kan je profiel vergrendelen en
        vragen om het te verwijderen.
      </p>
      <br />
      <h4>Melding bij onregelmatigheden</h4>
      <p>
        De Bobex-infrastructuur en alle gegevens van onze databanken worden
        gehost bij een externe firma die gespecialiseerd is in de bescherming
        van gegevens. Deze firma gebruikt de meest recente technologieën om onze
        gegevens op een veilige manier te beschermen. Indien er ooit een
        beveiligingsprobleem zou opduiken, zullen wij je hiervan onmiddellijk op
        de hoogte brengen.
      </p>
      <br />
      <h4>GDPR binnen Bobex</h4>
      <p>
        Wij volgen de Europese Privacyregels (GDPR) en hebben een Data
        Protection Manager aangesteld die de implementatie van alle GDPR regels
        binnen ons bedrijf nauwkeurig opvolgt. De Data Protection Manager is het
        aanspreekpunt voor alle vragen of opmerkingen omtrent data en privacy.
        Je kan onze Data Protection Manager bereiken door te mailen naar
        privacy@bobex.be.
      </p>
    </b-modal>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";

export default {
  name: "Footer",
  data() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: rgba(232, 251, 251, 1);
}
</style>
