<template>
  <div class="form mb-5 mb-md-0" id="form">
    <b-container class="py-4 text-white text-center" v-if="!submited">
      <h3>Ontvang offertes op maat</h3>
      <p>Laat je dak isoleren en geniet van de isolatiepremie!</p>
      <div class="text-left">
        <b-form @submit.prevent="onSubmit">
          <b-form-input
            class="my-3"
            :state="fNameValidation"
            v-model="form.firstname"
            placeholder="Voornaam*"
            required
          ></b-form-input>
          <b-form-input
            class="my-3"
            :state="lNameValidation"
            v-model="form.lastname"
            placeholder="Achternaam*"
            required
          ></b-form-input>
          <div class="row">
            <div class="col-8">
              <b-form-input
                :state="streetValidation"
                v-model="form.street"
                type="text"
                placeholder="Straat"
                required
              ></b-form-input>
            </div>
            <div class="col-4">
              <b-form-input
                :state="houseValidation"
                v-model="form.house_number"
                type="text"
                placeholder="Huisnummer"
                required
              ></b-form-input>
            </div>
          </div>
          <b-form-input
            class="my-3"
            :state="zipValidation"
            v-model="form.zip"
            placeholder="Postcode*"
            maxLength="6"
            required
          ></b-form-input>
          <b-form-input
            class="my-3"
            :state="emailValidation"
            v-model="form.email"
            type="email"
            placeholder="E-mail*"
            required
          ></b-form-input>
          <b-form-input
            class="my-3"
            :state="phoneValidation"
            v-model="form.phone_number"
            placeholder="Telefoonnummer*"
            maxLength="10"
            required
          ></b-form-input>

          <b-form-checkbox-group v-model="temp_answers" class="my-4">
            <b-form-checkbox value="3629"
              ><span class="fs--13">
                Ja, ik wens de nieuwsbrief te ontvangen</span
              ></b-form-checkbox
            >
          </b-form-checkbox-group>

          <b-button
            type="submit"
            class="btn-warning my-2"
            variant="warning"
            block
          >
            <b-spinner small variant="white" v-if="sakisolatieLoader" />
            <span v-else class="text-white fw--700">Bevestigen</span>
          </b-button>
          <p class="fs--10 m-0">
            Door op bevestigen te klikken ga ik er mee akkoord dat Mijn-Offertes
            mij gratis offertes opstuurt.
          </p>
        </b-form>
      </div>
    </b-container>
    <b-container class="py-4 text-white text-center" v-else>
      <h3>Bedankt voor uw aanvraag</h3>
      <p>
        Wij brengen je zo snel mogelijk in contact met erkende dakisolatie
        bedrijven uit je buurt.
      </p>
      <p>
        Nog vakmannen nodig? Vind de ideale aannemer voor elk project op
        <a href="https://www.bobex.be/nl-be/?aff=bobex">Bobex.be.</a>
      </p>
      <img
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.verbeterthuis.be/m/5555/ff5f3af64ae3/?event=6495&unique_conversion_id=${dakisolatieId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Form",
  data() {
    return {
      validationCheck: true,
      emailErr: true,
      temp_answers: [],
      form: {
        language: "nl_BE",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        street: "",
        house_number: "",
        zip: "",
        site_custom_url: "https://mijnoffertes.verbeterthuis.be/dakisolatie",
        site_custom_name: "Mijn-Offertes Dakisolatie",
        answers: null,
        site_subid: "",
        publisher_id: "",
      },
      submited: false,
    };
  },
  methods: {
    ...mapActions(["post_lead_form"]),
    onSubmit() {
      if (!this.emailErr) {
        this.confirmEmail();
      } else if (this.validationCheck && this.emailErr) {
        this.postLeadForm();
      }
    },
    confirmEmail() {
      this.$bvModal
        .msgBoxOk(
          "Je e-mail adres lijkt een spelfout te bevatten. Gelieve deze te controleren voordat u verder gaat.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Oké",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          this.emailErr = value;
        });
    },
    async postLeadForm() {
      let uncheckValue = this.temp_answers.includes("3629");
      if (uncheckValue) {
        this.form.answers = ["3628", "3629"];
      } else {
        this.form.answers = ["3628", "3630"];
      }
      if (this.validationCheck) {
        let resp = await this.post_lead_form({
          ...this.form,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.submited = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["dakisolatieId", "sakisolatieLoader"]),
    fNameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      const email = this.form.email.split("@");
      let check = email[1];
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        if (
          check == `gmail.com` ||
          check == `outlook.com` ||
          check == `yahoo.com` ||
          check == `icloud.com`
        ) {
          this.validationCheck = true;
          this.emailErr = true;
        } else {
          this.emailErr = false;
          this.validationCheck = true;
        }
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    streetValidation() {
      if (this.form.street == "") {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z ]*$/;

      if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      if (this.form.house_number == "") {
        this.validationCheck = false;
        return null;
      }

      let houseRegex = /(\w?[0-9]+[a-zA-Z0-9\- ]*)/;
      if (this.form.house_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.house_number.match(houseRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zipValidation() {
      if (this.form.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4})$/;
      if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.form {
  background: rgba(21, 47, 46, 1);
  margin-top: -275px;
  @media only screen and (max-width: 767.98px) {
    margin-top: -145px;
  }
}
.btn-warning {
  background: rgba(255, 102, 0, 1) !important;
  border: 1px solid rgba(255, 102, 0, 1) !important;
}
</style>
