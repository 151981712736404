<template>
  <div class="waarom">
    <b-container class="p-5 d-flex justify-content-center">
      <b-col sm="12" md="6" class="text-center">
        <h1 class="fs--md--45 fw--700 mb-4">
          Hoe krijg ik de premie <br />
          voor dakisolatie?
        </h1>
        <p class="fw--18 pb-2 pb-md-5 mb-2 mb-md-5">
          Sinds 2020 moeten alle woningen, die vóór 1 januari 2006 aangesloten
          zijn op het elektriciteitsnet, aan de dakisolatienorm voldoen. De
          <b>premie voor dakisolatie of zoldervloerisolatie</b> is bedoeld als
          een financieel duwtje in de rug.
        </p>
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "HoeSection",
};
</script>

<style lang="scss">
.waarom {
  background: rgba(232, 251, 251, 1);
}
</style>
