<template>
  <div class="app"><SpanplafondAfterSubmit /></div>
</template>

<script>
// @ is an alias to /src
import SpanplafondAfterSubmit from "@/layouts/SpanplafondAfterSubmit.vue";

export default {
  name: "Bedankt",
  components: {
    SpanplafondAfterSubmit,
  },
};
</script>
