<template>
  <div class="formSection pt-5">
    <b-container class="p-5">
      <b-row class="formSection-md">
        <b-col sm="12" md="7">
          <b-row class="mb-3" v-for="(data, index) in points" :key="index">
            <b-col cols="3" md="2" lg="1">
              <img :src="require(`@/assets/${data.icon}`)" />
            </b-col>
            <b-col cols="9" class="pl-3">
              <p class="fs--20 fw--500 mb-0 color--primary">
                {{ data.title }}
              </p>
              <p>
                {{ data.subTitle }}
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="5" lg="4"> <FormComponent /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import icon1 from "@/assets/icon_1.svg";
import icon2 from "@/assets/icon_2.svg";
import icon3 from "@/assets/icon_3.svg";
import FormComponent from "./Form";

export default {
  name: "formSection",
  data() {
    return {
      icon1,
      icon2,
      icon3,
      points: [
        {
          icon: "icon_1.svg",
          title: "Erkende isolatiebedrijven",
          subTitle:
            "Wij werken enkel met erkende isolatiebedrijven. Zo ben je zeker van je premie.",
        },
        {
          icon: "icon_2.svg",
          title: "Geniet van een comfortabel huis",
          subTitle:
            "Met dakisolatie blijft het aangenaam warm in de winter en lekker koel in de zomer.",
        },
        {
          icon: "icon_3.svg",
          title: "Vergelijk & bespaar",
          subTitle:
            "Vergelijk offertes voor dakisolatie en bespaar tot 30% op de eindfactuur!",
        },
      ],
    };
  },
  components: {
    FormComponent,
  },
};
</script>

<style lang="scss">
.formSection {
  background: white;
}
.formSection-md {
  @media only screen and (max-width: 767.98px) {
    flex-direction: column-reverse;
  }
}
</style>
